@custom-media --width-touch (width < 768px);
@custom-media --width-desktop-s (width >= 768px);

@custom-media --width-from-desktop-s (width >= 768px);

@custom-media --width-to-touch (width < 768px);

:root {
	--x0: 0px;
	--x025: 1px;
	--x05: 2px;
	--x1: 4px;
	--x2: 8px;
	--x3: 12px;
	--x4: 16px;
	--x5: 20px;
	--x6: 24px;
	--x8: 32px;
	--x10: 40px;
	--x12: 48px;
	--x15: 60px;
	--x20: 80px;
	--size-base: 4px;
	--size-grid: 20px;
	--tone-value-x1: 0.04;
	--tone-value-x2: 0.08;
	--tone-value-x3: 0.12;
	--tone-value-x4: 0.16;
	--tone-value-x6: 0.24;
	--tone-value-x8: 0.32;
	--tone-value-x12: 0.48;
	--tone-value-x18: 0.72;
	--tone-value-overlay: 0.48;
	--tone-value-base: 0.48;
	--tone-value-viewer: 0.88;
	--tone-value-toolbar: 0.24;
	--tone-value-hover: 0.04;
	--tone-value-active: 0.08;
	--tone-value-focus: 0.08;
	--depth0: none;
	--depth1: 0 2px 0 0 rgba(0, 0, 0, 0.04);
	--depth2: 0 4px 32px 0 rgba(0, 0, 0, 0.32);
	--depth3: 0 16px 48px 0 rgba(0, 0, 0, 0.48);
	--depth-inset: inset 0 2px 0 0 rgba(0, 0, 0, 0.04);
	--color-border: rgba(0,0,0,0.12);
	--color-border--hover: rgba(0,0,0,0.24);
	--color-border--active: rgba(0,0,0,0.48);
	--color-border--focus: rgba(0,0,0,0.24);
	--color-divider: rgba(0,0,0,0.08);
	--type-border-button: solid;
	--type-border-position: inset;
	--animation-duration-l: 0.4s;
	--animation-duration-m: 0.2s;
	--animation-duration-s: 0.1s;
	--transition-easing-default: cubic-bezier(0.3, 0.3, 0.5, 1);
	--padding-base: 16px;
	--padding-popup-base: 20px;
	--padding-popup-header: 16px;
	--color-primary: #005ff9;
	--color-primary--hover: #005CF1;
	--color-primary--active: #0059EA;
	--color-secondary: #ff9e00;
	--color-secondary--hover: #F59802;
	--color-secondary--active: #EB9305;
	--size-border-width: 1px;
	--size-border-width-tab: 2px;
	--size-border-width-bar: 4px;
	--size-border-width-accent: 8px;
	--size-border-width-accent-secondary: 4px;
	--size-border-radius: 2px;
	--size-border-radius-paper: 2px;
	--font-weight: 400;
	--font-weight-bold: 700px;
	--font-family: Helvetica, Arial, sans-serif;
	--font-size: 17px;
	--line-height: 24px;
	--size-control-height: 48px;
	--size-control-button-width-min: 130px;
	--size-control-button-icon-width: 48px;
	--size-control-button-slider-height: 24px;
	--size-control-button-slider-width: 12px;
	--size-control-button-slider-width-pointer: 16px;
	--size-control-button-large-height: 48px;
	--size-control-button-large-width: 48px;
	--size-control-button-small-height: 32px;
	--size-control-button-small-width: 32px;
	--size-control-field-width-min: 328px;
	--size-control-label-width: 184px;
	--size-control-check-height: 16px;
	--size-control-check-width: 16px;
	--size-progress: 4px;
	--size-progress-scroll: 12px;
	--size-dot-bullet: 4px;
	--size-dot-indicate: 8px;
	--size-dot-clickable: 12px;
	--size-arrow-height: 8px;
	--size-arrow-width: 12px;
	--size-popup-small: 420px;
	--size-popup-medium: 680px;
	--size-popup-large: 880px;
	--padding-control-horizontal: 12px;
	--padding-control-vertical: 0px;
	--padding-control-more: 8px;
	--padding-control-more-icon: 12px;
	--padding-control-button: 20px;
	--padding-control-button-icon: 20px;
	--padding-control-div: 4px;
	--padding-menu: 8px;
	--padding-tag: 8px;
	--padding-tab: 0px;
	--padding-arrow: 12px;
	--padding-table-horizontal: 16px;
	--padding-table-vertical: 16px;
	--padding-slice-top: 40px;
	--padding-slice-bottom: 60px;
	--margin-control: 12px;
	--margin-form-label-horizontal: 16px;
	--margin-form-label-vertical: 4px;
	--margin-form-tip: 4px;
	--margin-form-field-horizontal: 16px;
	--margin-form-field-vertical: 24px;
	--margin-form-field-set: 32px;
	--margin-icon: 12px;
	--margin-choice: 8px;
	--margin-menu: 2px;
	--margin-tooltip: 8px;
	--margin-tag: 8px;
	--margin-tab: 32px;
	--margin-bullet: 12px;
	--margin-attribute: 4px;
	--margin-popup: 8px;
	--size-icon-u-i: 16px;
	--size-icon-header: 20px;
	--size-icon-wysiwyg: 14px;
	--size-illustration-s: 48px;
	--size-illustration-x-m: 72px;
	--size-illustration-m: 96px;
	--size-illustration-l: 144px;
	--size-illustration-x-x-l: 228px;
	--size-avatar-x-s: 24px;
	--size-avatar-s: 32px;
	--size-avatar-m: 48px;
	--size-avatar-l: 96px;
	--size-avatar-x-l: 128px;
	--color-state-hover: rgba(0, 16, 61, 0.04);
	--color-state-active: rgba(0, 16, 61, 0.08);
	--color-overlay: rgba(0, 0, 0, 0.48);
	--color-text: #2C2D2E;
	--color-text-secondary: #919399;
	--color-text-contrast: #ffffff;
	--color-icon: #2C2D2E;
	--color-icon-contrast: #ffffff;
	--color-link: #005bd1;
	--color-link-visited: #528fdf;
	--color-link-contrast: #ffffff;
	--color-true-black: #000000;
	--color-true-white: #ffffff;
	--color-state: #00103D;
	--color-alert: #c1341b;
	--color-bg-highlight: #fff1ad;
	--color-icon-secondary: #BFC1C7;
	--color-icon-secondary--hover: #B7BAC1;
	--color-icon-secondary--active: #B0B3BC;
	--color-bg: #ffffff;
	--color-bg--hover: #F5F5F7;
	--color-bg--active: #EBECEF;
	--color-bg-popup: #ffffff;
	--color-bg-popup--hover: #F5F5F7;
	--color-bg-popup--active: #EBECEF;
	--color-bg-secondary: #F0F1F3;
	--color-bg-secondary--hover: #E6E8EC;
	--color-bg-secondary--active: #DDDFE4;
	--color-bg-contrast: #2C2D2E;
	--color-bg-contrast--hover: #2A2C2F;
	--color-bg-contrast--active: #282B2F;
	--color-bg-thumbnail: #00103d;
	--color-bg-thumbnail--hover: #00103D;
	--color-bg-thumbnail--active: #00103D;
	--color-success: #0DC268;
	--color-success--hover: #0CBB66;
	--color-success--active: #0CB465;
	--color-error: #ED0A34;
	--color-error--hover: #E40A34;
	--color-error--active: #DA0A35;
	--color-warning: #fffce0;
	--color-warning--hover: #F5F3D9;
	--color-warning--active: #EBE9D3;
	--color-bg-warning: #fffce0;
	--color-bg-warning--hover: #F5F3D9;
	--color-bg-warning--active: #EBE9D3;
	--color-rating: #ffd400;
	--color-rating--hover: #F5CC02;
	--color-rating--active: #EBC405;
	--color-notify: #ff9e00;
	--color-notify--hover: #F59802;
	--color-notify--active: #EB9305;
	--color-social-vk: #4680C2;
	--color-social-vk--hover: #437CBD;
	--color-social-vk--active: #4077B7;
	--color-social-ok: #ee8208;
	--color-social-ok--hover: #E47D0A;
	--color-social-ok--active: #DB790C;
	--color-social-mir: #117eda;
	--color-social-mir--hover: #107AD4;
	--color-social-mir--active: #1075CD;
	--color-social-fb: #0561af;
	--color-social-fb--hover: #055EAA;
	--color-social-fb--active: #055BA6;
	--color-social-twitter: #00bff3;
	--color-social-twitter--hover: #00B8EC;
	--color-social-twitter--active: #00B1E4;
	--color-social-dribbble: #ea4c89;
	--color-social-dribbble--hover: #E14A86;
	--color-social-dribbble--active: #D74783;
	--color-social-behance: #0057ff;
	--color-social-behance--hover: #0054F7;
	--color-social-behance--active: #0051EF;
	--color-palette-red1: #fc2c38;
	--color-palette-red1--hover: #F22B38;
	--color-palette-red1--active: #E82A38;
	--color-palette-red2: #eb1981;
	--color-palette-red2--hover: #E2197E;
	--color-palette-red2--active: #D8187C;
	--color-palette-red3: #ff71bb;
	--color-palette-red3--hover: #F56DB6;
	--color-palette-red3--active: #EB69B1;
	--color-palette-orange1: #ff8b2e;
	--color-palette-orange1--hover: #F5862F;
	--color-palette-orange1--active: #EB812F;
	--color-palette-orange2: rgba(255, 158, 0, 0.12);
	--color-palette-orange2--hover: rgba(255, 158, 0, 0.16);
	--color-palette-orange2--active: rgba(255, 158, 0, 0.2);
	--color-palette-yellow1: #ffc311;
	--color-palette-yellow1--hover: #F5BC13;
	--color-palette-yellow1--active: #EBB515;
	--color-palette-green1: #aee113;
	--color-palette-green1--hover: #A7D915;
	--color-palette-green1--active: #A0D016;
	--color-palette-green2: #59c354;
	--color-palette-green2--hover: #55BC53;
	--color-palette-green2--active: #52B552;
	--color-palette-green3: #37eb94;
	--color-palette-green3--hover: #35E291;
	--color-palette-green3--active: #33D98D;
	--color-palette-green4: #00b1b3;
	--color-palette-green4--hover: #00ABAE;
	--color-palette-green4--active: #00A4AA;
	--color-palette-green5: rgba(92, 186, 56, 0.12);
	--color-palette-green5--hover: rgba(92, 186, 56, 0.16);
	--color-palette-green5--active: rgba(92, 186, 56, 0.2);
	--color-palette-green6: rgba(0, 230, 180, 0.08);
	--color-palette-green6--hover: rgba(0, 230, 180, 0.12);
	--color-palette-green6--active: rgba(0, 230, 180, 0.16);
	--color-palette-blue1: #2951ff;
	--color-palette-blue1--hover: #274EF7;
	--color-palette-blue1--active: #264CEF;
	--color-palette-blue2: #00abf2;
	--color-palette-blue2--hover: #00A5EB;
	--color-palette-blue2--active: #009FE4;
	--color-palette-blue3: #26dfdf;
	--color-palette-blue3--hover: #24D7D9;
	--color-palette-blue3--active: #23CED2;
	--color-palette-blue4: rgba(0, 95, 249, 0.12);
	--color-palette-blue4--hover: rgba(0, 95, 249, 0.16);
	--color-palette-blue4--active: rgba(0, 95, 249, 0.2);
	--color-palette-violet1: #6c00ff;
	--color-palette-violet1--hover: #6801F7;
	--color-palette-violet1--active: #6301EF;
	--color-palette-violet2: #ae73ff;
	--color-palette-violet2--hover: #A76FF7;
	--color-palette-violet2--active: #A06BEF;
}

.font-size1 {
	font-size: 11px;
	line-height: 12px;
	font-weight: 400;
	font-family: Helvetica, Arial, sans-serif;
}
.font-size2 {
	font-size: 13px;
	line-height: 20px;
	font-weight: 400;
	font-family: Helvetica, Arial, sans-serif;
}
.font-size3 {
	font-size: 15px;
	line-height: 20px;
	font-weight: 400;
	font-family: Helvetica, Arial, sans-serif;
}
.font-size4 {
	font-size: 17px;
	line-height: 24px;
	font-weight: 400;
	font-family: Helvetica, Arial, sans-serif;
}
.font-size5 {
	font-size: 20px;
	line-height: 28px;
	font-weight: 400;
	font-family: Helvetica, Arial, sans-serif;
}
.font-size6 {
	font-size: 24px;
	line-height: 32px;
	font-weight: 400;
	font-family: Helvetica, Arial, sans-serif;
}
.font-size7 {
	font-size: 32px;
	line-height: 40px;
	font-weight: 400;
	font-family: Helvetica, Arial, sans-serif;
}
.font-size8 {
	font-size: 44px;
	line-height: 52px;
	font-weight: 400;
	font-family: Helvetica, Arial, sans-serif;
}
.font-h0 {
	font-size: 32px;
	line-height: 40px;
	font-weight: 700;
	font-family: Helvetica, Arial, sans-serif;
	margin-bottom: 20px;
	@media (--width-desktop-s) {
		margin-bottom: 24px;
	}
}
.font-h1 {
	font-size: 24px;
	line-height: 32px;
	font-weight: 700;
	font-family: Helvetica, Arial, sans-serif;
	margin-bottom: 16px;
	@media (--width-desktop-s) {
		font-weight: 400;
		margin-bottom: 20px;
	}
}
.font-h2 {
	font-size: 20px;
	line-height: 28px;
	font-weight: 700;
	font-family: Helvetica, Arial, sans-serif;
	margin-bottom: 12px;
	@media (--width-desktop-s) {
		font-weight: 400;
		margin-bottom: 16px;
	}
}
.font-h3 {
	font-size: 17px;
	line-height: 24px;
	font-weight: 700;
	font-family: Helvetica, Arial, sans-serif;
	margin-bottom: 12px;
	@media (--width-desktop-s) {
		font-weight: 400;
	}
}
.font-h4 {
	font-size: 15px;
	line-height: 20px;
	font-weight: 700;
	font-family: Helvetica, Arial, sans-serif;
	margin-bottom: 12px;
	@media (--width-desktop-s) {
		margin-bottom: 8px;
	}
}
.font-h5 {
	font-size: 15px;
	line-height: 20px;
	font-weight: 700;
	font-family: Helvetica, Arial, sans-serif;
	margin-bottom: 8px;
}
.font-h6 {
	font-size: 15px;
	line-height: 20px;
	font-weight: 700;
	font-family: Helvetica, Arial, sans-serif;
	margin-bottom: 8px;
}
.font-body {
	font-size: 17px;
	line-height: 24px;
	font-weight: 400;
	font-family: Helvetica, Arial, sans-serif;
	@media (--width-desktop-s) {
		font-size: 15px;
		line-height: 20px;
	}
}
.font-paragraph {
	font-size: 15px;
	line-height: 20px;
	font-weight: 400;
	font-family: Helvetica, Arial, sans-serif;
	margin-bottom: 24px;
	@media (--width-desktop-s) {
		margin-bottom: 8px;
	}
}
.font-list {
	font-size: 15px;
	line-height: 20px;
	font-weight: 400;
	font-family: Helvetica, Arial, sans-serif;
}
.font-list-item {
	font-size: 15px;
	line-height: 20px;
	font-weight: 400;
	font-family: Helvetica, Arial, sans-serif;
}
.font-footnote {
	font-size: 13px;
	line-height: 20px;
	font-weight: 400;
	font-family: Helvetica, Arial, sans-serif;
}
.font-lead {
	font-size: 17px;
	line-height: 24px;
	font-weight: 400;
	font-family: Helvetica, Arial, sans-serif;
}
.font-quote {
	font-size: 17px;
	line-height: 24px;
	font-weight: 400;
	font-family: Helvetica, Arial, sans-serif;
}
.font-table-header {
	font-size: 15px;
	line-height: 20px;
	font-weight: 700;
	font-family: Helvetica, Arial, sans-serif;
}
.font-control {
	font-size: 15px;
	line-height: 20px;
	font-weight: 400;
	font-family: Helvetica, Arial, sans-serif;
}
.font-control-label {
	font-size: 13px;
	line-height: 20px;
	font-weight: 700;
	font-family: Helvetica, Arial, sans-serif;
}
.font-tab {
	font-size: 13px;
	line-height: 20px;
	font-weight: 400;
	font-family: Helvetica, Arial, sans-serif;
	@media (--width-desktop-s) {
		font-size: 15px;
	}
}
.font-badge {
	font-size: 11px;
	line-height: 12px;
	font-weight: 700;
	font-family: Helvetica, Arial, sans-serif;
	letter-spacing: 1px;
	text-transform: uppercase;
}
.font-disclaimer {
	font-size: 11px;
	line-height: 12px;
	font-weight: 400;
	font-family: Helvetica, Arial, sans-serif;
}


@media (--width-desktop-s) {
	:root {
		--padding-base: 20px;
		--font-size: 15px;
		--line-height: 20px;
		--size-control-height: 32px;
		--size-control-button-width-min: 104px;
		--size-control-button-icon-width: 32px;
		--size-control-field-width-min: 380px;
		--padding-control-more-icon: 8px;
		--padding-control-button: 16px;
		--padding-control-button-icon: 16px;
		--margin-control: 8px;
		--margin-form-field-vertical: 20px;
		--margin-icon: 8px;
		--margin-tag: 4px;
		--margin-popup: 24px;

	}
}
